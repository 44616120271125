

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getLoanDetail, cancelLoan, acceptLoanOffer, addLoanAttachmentFile } from '@/scripts/api/login';
import * as library from '@/scripts/utils/library';
import LoanStatus from '@/components/LoanStatus.vue';
import BackButton from '@/components/BackButton.vue';
import UploadLoanFile from '@/components/UploadLoanFile.vue';

export default Vue.extend({
    components: {
        LoanStatus,
        BackButton,
        UploadLoanFile,
    },
    data() {
        return {
            data: {
                loan_id: 0,
                client_id: '',
                property_addr: '',
                loan_amt: 10000,
                status: '',
                create_time: '',
                expiry_time: '',
                expired: 'N',
                offer1: [],
                offer2: [],
                client_files: [],
            },
            cancelling: false,
            submitting: false,
        };
    },
    mounted() {
        this.loadLoanDetail(this.$route.params.loan_id);
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        formatDateTime(cellValue: any) {
            return library.formatDateTime(cellValue);
        },
        formatLoanStatus(cellValue: string, expired: string) {
            return library.formatLoanStatus(cellValue, expired);
        },
        formatNumber(cellValue: any) {
            return library.formatNumber(cellValue);
        },
        formatNumberCellValue(row: any, column: any, cellValue: any, index: any) {
            return library.formatNumber(cellValue);
        },
        addFile(file: any) {
            addLoanAttachmentFile(this.data.loan_id, file).then((response) => {
                    library.showMessage(this, '已成功收到後補文件 ' + file.name);
                }).catch((error) => {
                    library.errHandle(this, error);
                }).finally(() => {
                    //
                });
        },
        loadLoanDetail(loanId: string) {
            // const loan_id = 'L00000041';
            getLoanDetail(loanId).then((response) => {
                    this.data = response.data;
                }).catch((error) => {
                    library.errHandle(this, error);
                }).finally(() => {
                    //
                });
        },
        cancelLoanApp() {
            let isSuccess = false;
            this.$confirm('是否確認取消這貸款申請?', '', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning',
            }).then(() => {
                this.cancelling = true;
                cancelLoan(this.data.loan_id).then((response) => {
                    isSuccess = true;
                    console.debug('cancel success');
                    library.showMessage(this, '你的貸款申請已成功取消。');
                    this.$router.push('/loan-app');
                }).catch((error) => {
                    library.errHandle(this, error);
                }).finally(() => {
                    this.cancelling = false;
                });
            }).catch(() => {
                //
            });
        },
        acceptOffer(offerRow: any, offerType: string) {
            let isSuccess = false;
            this.$confirm('是否接受這出價 (貸款息率' + library.formatNumber(offerRow.offer_rate)
                            + '%，最高貸款金額 $' + library.formatNumber(offerRow.offer_amt)
                            + ')?', '', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning',
            }).then(() => {
                this.submitting = true;
                acceptLoanOffer(this.data.loan_id, offerRow.offer_id, offerType).then((response) => {
                    isSuccess = true;
                    console.debug('accept success');
                    library.showMessage(this, '你已成功接受這貸款申請出價，我們會盡快聯絡你跟相關貸款機構簽署貸款交件。');
                    this.$router.push('/loan-app');
                }).catch((error) => {
                    library.errHandle(this, error);
                }).finally(() => {
                    this.submitting = false;
                });
            }).catch(() => {
                //
            });
        },
    },
});
